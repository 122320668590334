import AsyncFactory from '../async';

export default
[
  {
    path: 'list',
    name: 'domainList',
    component: AsyncFactory(() => import(/* webpackChunkName: "Domains" */ '@/views/domains/list/DomainList')),
    meta:
      {
        title: 'domains.names.title',
        localize: true,
        menu: (root) => !!(((root.$store.getters.getWhiteLabel || {}).globals || {}).menuDomains || {}).showTabDomains,
        query:
          {
            desc: 'true',
            orderby: 'renewaldate',
            status: 200,
            page: 1,
          }
      },
    beforeEnter: (to, from, next) =>
    {
      if (!window.isp) next();
      else if (!window.isp.$store.getters.getWhiteLabel.globals.showMenuDomains) next(false);
      else if (window.isp.$store.getters.getWhiteLabel.globals.menuDomains.showTabDomains) next();
      else
      {
        const parent = window.isp.menu('myDomains');
        const children = (parent.children || []).filter(item => (typeof item.meta.menu === 'function' ? item.meta.menu(window.isp) : item.meta.menu));
        next(children[0] || false);
      }
    },
  },
  {
    path: 'contacts',
    name: 'domainContacts',
    component: AsyncFactory(() => import(/* webpackChunkName: "Domains" */ '@/views/domains/contact/ContactList')),
    meta:
      {
        title: 'domains.contacts.title',
        localize: true,
        menu: (root) => !!(((root.$store.getters.getWhiteLabel || {}).globals || {}).menuDomains || {}).showTabContacts,
      },
    beforeEnter: (to, from, next) =>
    {
      if (!window.isp) next();
      else if (window.isp.$store.getters.getWhiteLabel.globals.showMenuDomains) next();
      else if (window.isp.$store.getters.getWhiteLabel.globals.menuDomains.showTabContacts) next();
      else
      {
        const parent = window.isp.menu('myDomains');
        const children = (parent.children || []).filter(item => (typeof item.meta.menu === 'function' ? item.meta.menu(window.isp) : item.meta.menu));
        next(children[0] || false);
      }
    },
  },
];
