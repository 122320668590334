import Vue from 'vue';
import Router from 'vue-router';
import config from './config';
import AsyncFactory from './async';

import routesDomains from './routes/domains';
import routesEconomy from './routes/economy';
import routesAccount from './routes/account';
import routesUser from './routes/user';
import routesSupport from './routes/support';
import routesManageDomain from './routes/manageDomains';
import routesServices from './routes/services';
import routesBulkDomains from './routes/bulk';
import routesTools from './routes/tools';

import Login from './views/auth/LoginPage';
import badRoute from './views/BadRoute';

import iconGauge from '@/assets/img/icon/gauge.svg';
import iconFolder from '@/assets/img/icon/folder-open-solid.svg';
import iconEuro from '@/assets/img/icon/euro.svg';
import iconUser from '@/assets/img/icon/user-solid.svg';
import iconLife from '@/assets/img/icon/lifebuoy.svg';
import iconPlus from '@/assets/img/icon/plus.svg';
import iconWrench from '@/assets/img/icon/wrench.svg';
import iconRequest from '@/assets/img/icon/request.svg';

Vue.use(Router);

// import(/* webpackPrefetch: true, webpackChunkName: "DeviceDashboard" */ './views/About.vue');

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:
  [
    {
      path: '/',
      name: 'home',
      component: Login,
      meta:
        {
          title: (to, vueApp) =>
          {
            if (/^\d{1,3}(\.\d{1,3}){3}$/.test(window.location.hostname) && process.env.NODE_ENV === 'production')
            {
              window.location.href = 'https://www.google.com';
              return '';
            }
            else
            {
              let domain = window.location.hostname.split('.').slice(-2).join('.').toLowerCase();
              if (domain === 'localhost' || /^\d{1,3}(\.\d{1,3}){3}$/.test(domain)) domain = 'namesrs.com';
              return (vueApp ? vueApp.$store.getters.getWhiteLabel.globals.brandName : '') || (config.brand[domain] || {}).brandName || domain;
            }
          },
          public: true,
        }
    },
    {
      path: '/dashboard',
      alias: '/welcome',
      name: 'dashboard',
      component: AsyncFactory(() => import(/* webpackPrefetch: true, webpackChunkName: "Dashboard" */ './views/DashboardPage')),
      meta:
        {
          title: 'dashboard.title',
          localize: true,
          menu: true,
          icon: iconGauge,
        }
    },
    {
      path: '/forgot',
      name: 'forgotPassword',
      component: AsyncFactory(() => import(/* webpackChunkName: "Login" */ './views/auth/ForgottenPassword')),
      meta:
        {
          title: 'login.forgot',
          localize: true,
          public: true,
        }
    },
    {
      path: '/resetpassword/:token',
      name: 'resetPassword',
      component: AsyncFactory(() => import(/* webpackChunkName: "Login" */ './views/auth/ResetPassword')),
      meta:
        {
          title: 'reset_password.title',
          localize: true,
          public: true,
        }
    },
    {
      path: '/signup/:token?',
      alias: '/signupextended/:token?',
      name: 'signUp',
      component: AsyncFactory(() => import(/* webpackChunkName: "Login" */ './views/auth/SignUp')),
      meta:
        {
          title: 'signup.title',
          localize: true,
          public: true,
        }
    },
    {
      path: '/affiliate/:customName?/:token?',
      name: 'affiliate',
      component: AsyncFactory(() => import(/* webpackChunkName: "Affiliate" */ './views/auth/AffiliatePage')),
      meta:
        {
          title: 'affiliate.title',
          localize: true,
          public: true,
        }
    },
    {
      // menu Domains
      path: '/domain',
      redirect:
        {
          name: 'domainList',
          query:
            {
              desc: 'true',
              orderby: 'renewaldate',
              status: 200,
              page: 1,
            }
        },
      name: 'myDomains',
      component: AsyncFactory(() => import(/* webpackChunkName: "Domains" */ './views/domains/MyDomains')),
      children: routesDomains,
      meta:
        {
          title: 'domains.title',
          localize: true,
          menu: (root) => !!((root.$store.getters.getWhiteLabel || {}).globals || {}).showMenuDomains,
          icon: iconFolder,
        },
      beforeEnter: (to, from, next) =>
      {
        if (!window.isp) next();
        else if (window.isp.$store.getters.getWhiteLabel.globals.showMenuDomains) next();
        else next(false);
      },
    },
    {
      // single domain management
      path: '/domain/details/:itemID',
      redirect: '/domain/details/:itemID/status',
      name: 'manageDomain',
      component: AsyncFactory(() => import(/* webpackChunkName: "Manage" */ './views/domains/manage/ManageDomain')),
      children: routesManageDomain,
      meta:
        {
          title: 'domains.details.title',
          localize: true,
        },
      beforeEnter: (to, from, next) =>
      {
        if (!window.isp) next();
        else if (window.isp.$store.getters.getWhiteLabel.globals.showMenuDomains) next();
        else next(false);
      },
    },
    {
      // bulk domain labels
      path: '/domain/bulk',
      name: 'bulkDomains',
      redirect:
        {
          name: 'bulkDomainLabels',
        },
      component: AsyncFactory(() => import(/* webpackChunkName: "Bulk" */ './views/domains/bulk/BulkPage.vue')),
      children: routesBulkDomains,
    },
    {
      // menu Services
      path: '/services',
      redirect:
      {
        name: 'premiumDNS'
      },
      name: 'myServices',
      component: AsyncFactory(() => import(/* webpackChunkName: "Services" */ './views/services/MyServices')),
      children: routesServices,
      meta:
        {
          title: 'services.title',
          localize: true,
          menu: (root) => !!((root.$store.getters.getWhiteLabel || {}).globals || {}).showMenuServices,
          icon: iconFolder,
        },
      beforeEnter: (to, from, next) =>
      {
        if (!window.isp) next();
        else if (window.isp.$store.getters.getWhiteLabel.globals.showMenuServices) next();
        else next(false);
      },
    },
    {
      path: '/requests',
      name: 'wrapRequests',
      redirect:
        {
          name: 'domainRequests',
        },
      component: AsyncFactory(() => import(/* webpackChunkName: "Requests" */ '@/views/domains/MyRequests.vue')),
      meta:
        {
          title: 'domains.requests.title',
          localize: true,
          menu: (root) => !!(((root.$store.getters.getWhiteLabel || {}).globals || {}).menuDomains || {}).showTabRequests,
          icon: iconRequest,
        },
      children:
      [
        {
          path: '',
          name: 'domainRequests',
          component: AsyncFactory(() => import(/* webpackChunkName: "Requests" */ '@/views/domains/request/RequestList')),
          meta:
            {
              title: 'domains.requests.title',
              localize: true,
              menu: (root) => !!(((root.$store.getters.getWhiteLabel || {}).globals || {}).menuDomains || {}).showTabRequests,
              icon: iconRequest,
              query:
                {
                  desc: 'true',
                  orderby: 'created',
                  page: 1,
                }
            },
          beforeEnter: (to, from, next) =>
          {
            if (!window.isp) next();
            //else if (window.isp.$store.getters.getWhiteLabel.globals.showMenuDomains) next();
            else if (window.isp.$store.getters.getWhiteLabel.globals.menuDomains.showTabRequests) next();
            else
            {
              //const parent = window.isp.menu('myDomains');
              //const children = (parent.children || []).filter(item => (typeof item.meta.menu === 'function' ? item.meta.menu(window.isp) : item.meta.menu));
              //next(children[0] || false);
              next(false);
            }
          },
        },
      ]
    },
    {
      // menu Economy
      path: '/economy',
      redirect:
        {
          name: 'invoices',
          query:
            {
              desc: 'true',
              orderby: 'created',
              page: 1,
            }
        },
      name: 'economy',
      component: AsyncFactory(() => import(/* webpackChunkName: "Economy" */ './views/economy/EconomyPage')),
      children: routesEconomy,
      meta:
        {
          title: 'economy.title',
          localize: true,
          menu: (root) => !!((root.$store.getters.getWhiteLabel || {}).globals || {}).showMenuEconomy,
          icon: iconEuro,
        },
      beforeEnter: (to, from, next) =>
      {
        if (!window.isp) next();
        else if (window.isp.$store.getters.getWhiteLabel.globals.showMenuEconomy) next();
        else next(false);
      },
    },
    {
      // menu Account
      path: '/account',
      redirect: '/account/profile',
      name: 'myAccount',
      component: AsyncFactory(() => import(/* webpackChunkName: "Account" */ './views/account/AccountPage')),
      children: routesAccount,
      meta:
        {
          title: 'account.title',
          localize: true,
          menu: (root) => !!((root.$store.getters.getWhiteLabel || {}).globals || {}).showMenuAccount,
          icon: iconUser,
        },
      beforeEnter: (to, from, next) =>
      {
        if (!window.isp) next();
        else if (window.isp.$store.getters.getWhiteLabel.globals.showMenuAccount) next();
        else next(false);
      },
    },
    {
      // menu User
      path: '/user',
      redirect: '/user/profile',
      name: 'myUser',
      component: AsyncFactory(() => import(/* webpackChunkName: "User" */ './views/user/UserPage')),
      children: routesUser,
      meta:
        {
          title: 'user.title',
          localize: true,
          icon: iconUser,
        },
    },
    {
      // menu Tools
      path: '/tools',
      name: 'tools',
      redirect:
        {
          name: 'toolsMain'
        },
      component: AsyncFactory(() => import(/* webpackChunkName: "Tools" */ './views/tools/ToolsPage')),
      children: routesTools,
      meta:
        {
          title: 'tools.title',
          localize: true,
          menu: (root) => !!((root.$store.getters.getWhiteLabel || {}).globals || {}).showMenuTools,
          icon: iconWrench,
        },
      beforeEnter: (to, from, next) =>
      {
        if (!window.isp) next();
        else if (window.isp.$store.getters.getWhiteLabel.globals.showMenuTools) next();
        else next(false);
      },
    },
    {
      // menu Support
      path: '/support',
      redirect: '/support/opentickets',
      name: 'support',
      component: AsyncFactory(() => import(/* webpackChunkName: "Support" */ './views/support/SupportPage')),
      children: routesSupport,
      meta:
        {
          title: 'support.title',
          localize: true,
          menu: (root) => !!((root.$store.getters.getWhiteLabel || {}).globals || {}).showMenuSupport,
          icon: iconLife,
        },
      beforeEnter: (to, from, next) =>
      {
        if (!window.isp) next();
        else if (window.isp.$store.getters.getWhiteLabel.globals.showMenuSupport) next();
        else next(false);
      },
    },
    {
      // menu Add order
      path: '/order',
      name: 'orderService',
      component: AsyncFactory(() => import(/* webpackChunkName: "Order" */ './views/order/OrderService')),
      meta:
        {
          title: 'order.title',
          localize: true,
          menu: false,
          icon: iconPlus,
        }
    },
    {
      // Basket / Cart
      path: '/checkout',
      name: 'checkout',
      component: AsyncFactory(() => import(/* webpackChunkName: "Checkout" */ './views/checkout/BasketPage')),
      meta:
        {
          title: 'checkout.title',
          localize: true,
        },
      beforeEnter: (to, from, next) =>
      {
        if (!window.isp) next();
        else if (window.isp.$store.getters.getWhiteLabel.globals.enableBasket) next();
        else next(false);
      },
    },
    {
      path: '/searchdomains',
      name: 'search',
      component: AsyncFactory(() => import(/* webpackChunkName: "Search" */ './views/search/SearchDomains')),
      meta:
        {
          title: 'search.title',
          localize: true,
          public: true,
        }
    },
    {
      path: '/searchdomainsbulk',
      name: 'searchBulk',
      component: AsyncFactory(() => import(/* webpackChunkName: "Search" */ './views/search/SearchBulk')),
      meta:
        {
          title: 'search.title',
          localize: true,
          public: true,
        }
    },

    {
      path: '*', // should be last, otherwise matches everything
      component: badRoute,
      meta:
        {
          title: 'NOT FOUND',
          public: true
        }
    }
  ]
});

// https://stackoverflow.com/a/63263736 - ignore NavigationDuplicated errors but keep other errors
const originalPush = router.push;
router.push = function push(location, onResolve, onReject)
{
  if (onResolve || onReject)
  {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch((err) =>
  {
    if (err && err.name === 'NavigationDuplicated')
    {
      return err;
    }

    return Promise.reject(err);
  });
};

export default router;
